import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Data } from "../../App";
import iconMenu from "../../assets/dehaze.png";
import rafflesHelpers from "../../helpers/raffles";
const NavBarDetails = ({ setmenu, menu, id, user }) => {
  const data = useContext(Data);

  const [logo, setLogo] = useState([]);
  const [listMenu, setListMenu] = useState([]);
  useEffect(() => {
    console.log("navbar");
    console.log(id);
    if (id) {
      getMenu(id);
    }
  }, [id]);
  const getMenu = async (id) => {
    const params = { sections: 1, logo: 1 };
    const menu = await rafflesHelpers.raffleId(id, params);
    setLogo(menu.data.myRaffles.logo);
    setListMenu(menu.data.myRaffles.sections);
  };

  const navigation = useNavigate();
  return (
    <div>
      <div className="topNavRaffle">
        <h4 className="userName">
          <img src={logo} className="logo" />
        </h4>
        <div>
          <ul className="menuDesktop">
            <li className="botonNav">
              <a href={`/rifa/${data.data.user.userName}/${id}/#mainImage`}>
                Inicio
              </a>
            </li>
            {listMenu.map((option, i) => (
              <li className="botonNav" key={option._id}>
                <a href={`/rifa/${data.data.user.userName}/${id}/#AS${i}`}>
                  {option.menu}
                </a>
              </li>
            ))}

            <li
              className="botonNav buyBoton"
              onClick={() => navigation(`./buyTikets/${user}/${id}`)}
            >
              Comprar boletos
            </li>
          </ul>
          <button className="movilMenuBtn" onClick={() => setmenu(!menu)}>
            <img src={iconMenu} />
          </button>
        </div>
      </div>
      <ul className={menu ? "movilMenu" : "movilMenu2"}>
        <li className="botonNav">
          <a href={`/rifa/${data.data.user.userName}/${id}/#mainImage`}>
            Inicio
          </a>
        </li>
        {listMenu.map((option, i) => (
          <li className="botonNav" key={option._id}>
            <a href={`/rifa/${data.data.user.userName}/${id}/#AS${i}`}>
              {option.menu}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NavBarDetails;
