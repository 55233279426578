import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import NavBarDetails from "../components/navbar/NavBarDetails";
import RaffleDetailsScreen from "../screens/public/DetallesRifa/RaffleDetailsScreen";
import TiketsScreen from "../screens/public/Tikets/TiketsScreen";

const RouterDetails = () => {
  const [menu, setmenu] = useState(false);
  const [id, setId] = useState();
  const [user, setUser] = useState();
  return (
    <div id="detailsContainer">
      <NavBarDetails setmenu={setmenu} menu={menu} id={id} user={user} />
      <Routes>
        <Route
          path="/buyTikets/:user/:id"
          element={<TiketsScreen setId={setId} setUser={setUser} />}
        />
        <Route
          path="/:user/:id"
          element={<RaffleDetailsScreen setId={setId} setUser={setUser} />}
        />
        <Route path="/*" element={<Navigate to="/home" replace />} />
      </Routes>
    </div>
  );
};

export default RouterDetails;
