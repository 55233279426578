import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Data } from "../App";
import LoginScreen from "../screens/public/Login/LoginScreen";

import AppRouterRifa from "./AppRouterRifa";
import RouterDetails from "./RouterDetails";

export const AppRouter = () => {
  const data = useContext(Data);

  return (
    <Router>
      <Routes>
        {!data.data.user.login && (
          <Route path="/login" element={<LoginScreen />} />
        )}
        <Route path="/rifa/*" element={<RouterDetails />} />

        <Route path="/*" element={<AppRouterRifa />} />
      </Routes>
    </Router>
  );
};
