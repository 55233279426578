import { AppRouter } from "./routers/AppRouter";
import { createContext, useEffect, useState } from "react";
import "./App.css";
import success from "./assets/done.png";
import error from "./assets/error_outline.png";
export const Data = createContext({});
function App() {
  const icons = {
    success,
    error,
  };
  useEffect(() => {
    getData();
  }, []);

  const initialData = {
    user: { name: null, token: null, login: false },
  };
  const [data, setData] = useState(initialData);
  const [loading, setloading] = useState(false);
  const initialState = {
    msg: "",
    state: false,
    icon: "",
    type: "",
  };
  const [msg, setmsg] = useState(initialState);
  useEffect(() => {
    if (msg.state)
      setTimeout(() => {
        setmsg(initialState);
      }, 4000);
  }, [msg]);
  const getData = async () => {
    const data = (await localStorage.getItem("user")) || "";
    if (data !== "") {
      console.log(data);
      setData({ user: { ...JSON.parse(data), login: true } });
    }
  };
  const cleanSesion = async () => {
    await localStorage.removeItem("user");
    console.log("helado");
    setData(initialData);
  };
  const snackbar = {
    success: (msg) =>
      setmsg({
        msg,
        icon: "success",
        state: true,
        type: "success",
      }),
    warning: (msg) =>
      setmsg({
        msg,
        state: true,
        type: "warning",
      }),
    error: (msg) =>
      setmsg({
        msg,
        state: true,
        icon: "error",
        type: "error",
      }),
    info: (msg) =>
      setmsg({
        msg,
        state: true,
        type: "info",
      }),
  };
  return (
    <Data.Provider value={{ data, setData, cleanSesion, setloading, snackbar }}>
      {loading && <div class="loading">Loading&#8230;</div>}
      {msg.state && (
        <div class={`${msg.type} msgContainer`}>
          <img src={icons[msg.icon]} className="icon" onClick={() => {}} />
          <div>{msg.msg}</div>
        </div>
      )}
      <AppRouter />
    </Data.Provider>
  );
}

export default App;
