import React, { useContext, useEffect } from "react";
import "./Login.css";
import loginHelpers from "../../../helpers/login";
import { Data } from "../../../App";
const LoginScreen = () => {
  const data = useContext(Data);

  const loginUser = async (event) => {
    event.preventDefault();

    try {
      const response = await loginHelpers.loginAndSetData({
        userName: event.target[0].value,
        password: event.target[1].value,
      });
      if (response.status === 200) {
        const { userName, token } = response.data.user;
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(response.data.user));

        data.setData({ user: { userName, token, login: true } });
      } else {
        console.log("manejar error contrasena incorrecta");
      }
    } catch (err) {
      console.log("manejar error");
    }
  };
  return (
    <div className="containerLoginBox">
      <form className="loginBox" onSubmit={loginUser}>
        <div className="containerLogo">Ingresa</div>
        <div className="containerData">
          <div className="labelLogin">Usuario</div>
          <input type="text" name="user" className="inLogin" />
          <div className="labelLogin">Contrasena</div>
          <input type="text" name="password" className="inLogin" />
        </div>
        <div className="containerButtons">
          <button className="loginButton">Entrar</button>
        </div>
      </form>
    </div>
  );
};

export default LoginScreen;
