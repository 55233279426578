export const map = [
  {
    state: "Aguascalientes",
    states: [
      "Aguascalientes	",
      "Asientos",
      "Calvillo",
      "Cosío",
      "Jesús María",
      "Pabellón de Arteaga",
      "Rincón de Romos",
      "San José de Gracia",
      "Tepezalá",
      "El Llano",
      "San Francisco de los Romo",
    ],
  },
  {
    state: "Baja California",
    cities: [
      "Ensenada",
      "Mexicali",
      "Tecate",
      "Tijuana",
      "Playas de Rosarito",
      "San Quintín",
    ],
  },
  {
    state: "Baja California Sur",
    cities: ["Comondú", "Mulegé", "La Paz", "Los Cabos", "Loreto"],
  },
  { state: "Campeche" },
  { state: "Coahuila" },
  { state: "Colima" },
  { state: "Chiapas" },
  { state: "Chihuahua" },
  { state: "Durango" },
  { state: "Distrito Federal" },
  { state: "Guanajuato" },
  { state: "Guerrero" },
  { state: "Hidalgo" },
  { state: "Jalisco" },
  { state: "México" },
  { state: "Michoacán" },
  { state: "Morelos" },
  { state: "Nayarit" },
  { state: "Nuevo León" },
  { state: "Oaxaca" },
  { state: "Puebla" },
  { state: "Querétaro" },
  { state: "Quintana Roo" },
  { state: "San Luis Potosí" },
  { state: "Sinaloa" },
  { state: "Sonora" },
  { state: "Tabasco" },
  { state: "Tamaulipas" },
  { state: "Tlaxcala" },
  { state: "Veracruz" },
  { state: "Yucatán" },
  { state: "Zacatecas" },
];
