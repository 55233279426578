import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import { Data } from "../App";
import { Navbar } from "../components/navbar/navbar";
import AdminHomeScreen from "../screens/private/AdminHome/AdminHomeScreen";
import AdminRaffleDetailsScreen from "../screens/private/RaffleDetails/AdminRaffleDetailsScreen";
import RaffleDetailsScreen from "../screens/public/DetallesRifa/RaffleDetailsScreen";
import HomeScreen from "../screens/public/Home/HomeScreen";
import LoginScreen from "../screens/public/Login/LoginScreen";
import TiketsScreen from "../screens/public/Tikets/TiketsScreen";
import "./router.styles.css";

const AppRouterRifa = () => {
  const data = useContext(Data);

  return (
    <div className="generalBody">
      <Navbar />
      {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
      <Routes>
        <Route path="/home" element={<HomeScreen />} />
        <Route path="/details" element={<RaffleDetailsScreen />} />
        <Route path="/tikets" element={<TiketsScreen />} />
        {data.data.user.login && (
          <Route path="/adminHome" element={<AdminHomeScreen />} />
        )}

        <Route path="/raffleDetails" element={<AdminRaffleDetailsScreen />} />
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
    </div>
  );
};

export default AppRouterRifa;
