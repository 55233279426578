import React, { useEffect, useState } from "react";
import "./Tikets.css";
import magnify from "../../../assets/search.png";
import { useParams } from "react-router-dom";
import rafflesHelpers from "../../../helpers/raffles";
import close from "../../../assets/close.png";
import { map } from "../../../helpers/cities";

const TiketsScreen = ({ navigate, setId, setUser }) => {
  let { id, user } = useParams();
  const [backRaffle, setBackraffle] = useState({ state: false });
  const [raffle, setraffle] = useState({ state: false, raffle: [], price: 0 });
  const [search, setSearch] = useState("");
  const [tiketsList, settiketsList] = useState([]);
  const [tiketsProp, settiketsProp] = useState([]);
  const [fullProp, setfullProp] = useState([]);
  const [tikets, settikets] = useState([]);
  const [popup, setpopup] = useState(false);
  const [popgenerator, setpopgenerator] = useState(false);

  setId(id);
  setUser(user);
  useEffect(() => {
    getTikets();
  }, []);
  useEffect(() => {
    if (tikets.length === raffle.numbersTiket) {
      console.log("AGREGANDO A LISTA");
      setTiketValue(tikets.map((tik) => tik._id));
      settiketsList([...tiketsList, tikets]);
      settikets([]);
    }
  }, [tikets]);
  useEffect(() => {
    setTiketsArr();
  }, [raffle]);
  const setTiketsArr = async () => {
    /*  console.log("raffleeee");
    console.log(raffle.state);
    console.log(raffle.tikets); */
    if (raffle.state) {
      const selected = raffle.tikets.filter((raf) => raf.state === /* 3 */ 5);
      const multiArray = await separateArrays(selected, raffle.numbersTiket);
      console.log(multiArray);
      settiketsList([...multiArray]);
    }
  };
  const getTikets = async () => {
    try {
      const raffle = await rafflesHelpers.raffleId(id, {
        tikets: 1,
        sizeTikets: 1,
        price: 1,
        numbersTiket: 1,
        cellphone: 1,
        awaitingTime: 1,
      });
      console.log(raffle);
      settikets([]);
      settiketsList([]);

      setraffle({
        ...raffle.data.myRaffles,
        state: true,
        tikets: raffle.data.myRaffles.tikets,
      });
      setBackraffle({
        ...raffle.data.myRaffles,
        tikets: raffle.data.myRaffles.tikets,
      });
    } catch (e) {}
  };
  const setTiket = async (data) => {
    setraffle({
      ...raffle,
      tikets: raffle.tikets.map((raf) => {
        if (data._id === raf._id) {
          if (data.state === 1 || data.state === 2) {
            settikets([...tikets, data]);

            return { ...raf, state: /* 2 */ 4 };
          } else {
            settikets(tikets.filter((tik) => tik._id !== data._id));

            return { ...raf, state: 1 };
          }
        }
        return raf;
      }),
    });
    setBackraffle({
      ...backRaffle,
      tikets: backRaffle.tikets.map((raf) => {
        if (data._id === raf._id) {
          if (data.state === 1) {
            return { ...raf, state: /* 2 */ 4 };
          } else {
            return { ...raf, state: 1 };
          }
        }
        return raf;
      }),
    });
  };
  const setTiketArr = async (data) => {
    const ids = data.map((tik) => tik._id);
    setraffle({
      ...raffle,
      tikets: raffle.tikets.map((raf) => {
        if (ids.includes(raf._id)) {
          settikets([...tikets, ...data]);

          return { ...raf, state: /* 2 */ 4 };
        }
        return raf;
      }),
    });
    setBackraffle({
      ...backRaffle,
      tikets: backRaffle.tikets.map((raf) => {
        if (ids.includes(raf._id)) {
          return { ...raf, state: /* 2 */ 4 };
        }
        return raf;
      }),
    });
  };
  const setTiketArrNo = async (data) => {
    const ids = data.map((tik) => tik._id);
    console.log("NO tikect");

    setraffle({
      ...raffle,
      tikets: raffle.tikets.map((raf) => {
        if (ids.includes(raf._id)) {
          return { ...raf, state: /* 3 */ 5 };
        }
        return raf;
      }),
    });
    setBackraffle({
      ...backRaffle,
      tikets: backRaffle.tikets.map((raf) => {
        if (ids.includes(raf._id)) {
          return { ...raf, state: /* 3 */ 5 };
        }
        return raf;
      }),
    });
    settiketsProp([]);
    setfullProp([]);
    setpopgenerator(false);
  };
  const setTiketValue = (data, value = /* 3 */ 5) => {
    setraffle({
      ...raffle,
      tikets: backRaffle.tikets.map((raf) => {
        if (data.includes(raf._id)) {
          return { ...raf, state: value };
        }
        return raf;
      }),
    });
    setBackraffle({
      ...backRaffle,
      tikets: backRaffle.tikets.map((raf) => {
        if (data.includes(raf._id)) {
          return { ...raf, state: value };
        }
        return raf;
      }),
    });
  };
  const handlerRemve = async (tik) => {
    console.log(tik);
    const rmv = tiketsList.filter((t) => t === tik);

    settiketsList(tiketsList.filter((t) => t !== tik));
    const ids = tik.map((t) => t._id);

    setraffle({
      ...raffle,
      tikets: await removeTikets(ids, raffle),
    });
    setBackraffle({
      ...raffle,
      tikets: await removeTikets(ids, backRaffle),
    });
  };
  const removeTikets = async (ids, raffles) =>
    raffles.tikets.map((tiket) => {
      if (ids.includes(tiket._id)) {
        console.log(tiket._id + "hey");
        return { ...tiket, state: 1 };
      } else {
        return tiket;
      }
    });
  const handlerChange = (data) => {
    console.log(data.target.value);
    setSearch(data.target.value);
  };
  const handlerSearch = () => {
    console.log(search);
    if (search === "") {
      setraffle({
        ...raffle,
        tikets: backRaffle.tikets,
      });
    } else {
      setraffle({
        ...raffle,
        tikets: backRaffle.tikets.filter((tik) =>
          tik.number.toString().includes(search)
        ),
      });
    }
  };
  const handlerPopUp = () => {
    if (tiketsList.length === 0) return;
    if (tikets.length !== 0) return;
    setpopup(true);
  };
  const handlerBuy = (event) => {
    event.preventDefault();

    if (tiketsList.length === 0) return;
    if (tikets.length !== 0) return;
    if (event.target[0].value === "") return;
    if (event.target[1].value === "") return;
    if (event.target[2].value === "") return;
    if (event.target[3].value === "") return;
    const selectedToBuy = raffle.tikets.filter(
      (tik) => tik.state === /* 3 */ 5
    );
    const onlyNumbers = selectedToBuy.map((tik) => tik.number);
    console.log(onlyNumbers);
    console.log("buy");
    const tiketsN = backRaffle.tikets.filter((tik) => tik.state == 4);
    const nums = tiketsN.map((tik) => tik.number);
    const str = nums.join(",").replace(" ", "%20");
    const currentUrl = `https://rifasysorteos.com/rifa/${user}/${id}`;
    const wp = `https://wa.me/${
      raffle.cellphone
    }?text=Hola%20me%20gustaria%20comprar%20los%20siguientes%20numeros%20\n${onlyNumbers.join(
      ","
    )}%20\n${str}%20\nque%20vi%20en%20\n${currentUrl}%20\nmis%20datos%20son%20los%20siguientes%20\nNombre:%20${decodeURI(
      event.target[0].value
    )}%20\nEstado:%20${decodeURI(
      event.target[1].value
    )}%20\nCiudad:%20${decodeURI(
      event.target[2].value
    )}%20\nTelefono:%20${decodeURI(event.target[3].value)}`;
    updateTikets(selectedToBuy, {
      name: event.target[0].value,
      state: event.target[1].value,
      city: event.target[2].value,
      phone: event.target[3].value,
    });
    window.location.assign(wp);
  };
  const updateTikets = async (tikets, customer) => {
    const resp = await rafflesHelpers.updateTikets(
      tikets,
      raffle._id,
      customer
    );
    console.log(resp);
  };
  const autoGenerate = async () => {
    if (tikets.length === 0) return setpopgenerator(true);
    const quantity = raffle.numbersTiket - tikets.length;
    console.log(quantity);
    const randomNumbers = await selectRandomNumbers(
      raffle.tikets.filter((tik) => tik.state === 1),
      quantity
    );
    console.log(randomNumbers);
    setTiketArr(randomNumbers);
    /* for (let i = 0; i < quantity; i++) {
      setTiket(randomNumbers[i]);
    } */
  };
  const selectRandomNumbers = async (lst, n) => {
    const result = [];
    const len = lst.length;

    for (let i = 0; i < n; i++) {
      const randomIndex = Math.floor(Math.random() * len);
      result.push(lst[randomIndex]);
    }

    return result;
  };
  const handlerGenerate = async (event) => {
    event.preventDefault();
    if (event.target[0].value === "") return;
    console.log(event.target[0].value);
    console.log(raffle.numbersTiket);
    const randomNumbers = await selectRandomNumbers(
      raffle.tikets.filter((tik) => tik.state === 1),
      raffle.numbersTiket * Number(event.target[0].value)
    );
    const multiArray = await separateArrays(randomNumbers, raffle.numbersTiket);
    settiketsProp(multiArray);
    setfullProp(randomNumbers);
    console.log();
    /*  setTiketArrNo(randomNumbers); */
  };
  const separateArrays = async (inputArray, arraySize) => {
    const result = [];
    let currentArray = [];

    for (let i = 0; i < inputArray.length; i++) {
      currentArray.push(inputArray[i]);

      if (currentArray.length === arraySize) {
        result.push(currentArray);
        currentArray = [];
      }
    }

    if (currentArray.length > 0) {
      result.push(currentArray);
    }

    return result;
  };
  const isValidDate = (at) => {
    let tiketTime = new Date(at);

    let awaitingTime = new Date();
    awaitingTime.setHours(awaitingTime.getHours() - raffle.awaitingTime);

    if (tiketTime.getTime() > awaitingTime.getTime()) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <div className="bodyTikets">
      {popup && (
        <div className="popUpImgs">
          <form onSubmit={handlerBuy} className="bodyImgs">
            <div className="headPopUp">
              <img
                src={close}
                className="closebtn"
                onClick={() => setpopup(false)}
              />
            </div>
            <div className="containerInData">
              <label name="name" className="textInData">
                Nombre
              </label>
              <input
                type="text"
                className="inData"
                name="name"
                placeholder=""
              />
              <label name="state" className="textInData">
                Estado
              </label>
              <select name="state" className="inData">
                {map.map((val) => (
                  <option value={val.state}>{val.state}</option>
                ))}
              </select>
              <label name="city" className="textInData">
                Ciudad
              </label>
              <input type="text" className="inData" name="city" />
              <label name="name" className="textInData">
                Telefono/Celular
              </label>
              <input type="text" className="inData" name="cellphone" />
            </div>
            <div className="footerPopUp">
              <button type="submit" className="btnSend">
                Enviar
              </button>
            </div>
          </form>
          <div className="bodyPopUp"></div>
        </div>
      )}
      {popgenerator && (
        <div className="popUpImgs">
          <form onSubmit={handlerGenerate} className="bodyImgs">
            <div className="headPopUp">
              <img
                src={close}
                className="closebtn"
                onClick={() => setpopgenerator(false)}
              />
            </div>
            <div className="containerInData">
              <label name="state" className="textInData">
                Cantidad de boletos a generar
              </label>

              <input type="text" className="inData" name="qtyGen" />
              {tiketsProp.map((tikList, o) => (
                <div className="tiketSelected" key={`tiketComplete${o}`}>
                  <div>
                    {tikList.map((t) =>
                      o + 1 != raffle.numbersTiket
                        ? `${t.number} ,`
                        : `${t.number} `
                    )}
                  </div>
                  {/*  <img
                    src={close}
                    className="imgClose"
                    onClick={() => handlerRemve(tikList)}
                  /> */}
                </div>
              ))}
            </div>
            <div className="footerPopUp">
              <button
                type="submit"
                className={tiketsProp.length > 0 ? "btnSendA btnl" : "btnSend"}
              >
                Generar
              </button>
              {tiketsProp.length > 0 && (
                <button
                  type="button"
                  className="btnSend"
                  onClick={() => setTiketArrNo(fullProp)}
                >
                  Aceptar
                </button>
              )}
            </div>
          </form>
          <div className="bodyPopUp"></div>
        </div>
      )}

      <div className="buttonsContainer">
        <div className="headContainer">
          <h3 className="titleTiket">Numeros disponibles</h3>
          <div className="contentSearch">
            <input
              className="serchBox"
              name="search"
              onChange={handlerChange}
              value={search}
            />
            <img src={magnify} className="magnify" onClick={handlerSearch} />
          </div>
        </div>
        <p className="description">
          Un boleto {raffle.numbersTiket} oportunidades de ganar. Precio por
          boleto es de ${raffle.price} pesos mxn, selecciona abajo tus boletos y
          mucha suerte!!!
        </p>
        {raffle.state && (
          <div className="tiketsContainer">
            {raffle.tikets.map((tiket) => (
              <button
                className={`btnTiket ${
                  tiket.state === 1
                    ? "unselected"
                    : tiket.state === /* 2 */ 4
                    ? "selected"
                    : tiket.state === /* 2 */ 5
                    ? "blocked"
                    : tiket.state === 2 && isValidDate(tiket.at)
                    ? "unselected"
                    : "notShow"
                }`}
                key={tiket._id}
                onClick={() =>
                  tiket.state !== /* 3 */ 5
                    ? setTiket(tiket)
                    : console.log("bloqueado")
                }
              >
                {tiket.number.toString().padStart(raffle.sizeTikets, "0")}
              </button>
            ))}
          </div>
        )}
      </div>
      <div className="bagContainer">
        <div className="bagBox">
          <div className="titleBag">Seleccionados</div>
          <div className="containeralmost">
            {tikets.length > 0 && (
              <div className="almosttiket">{`Faltan ${
                raffle.numbersTiket - tikets.length
              } numeros para un boleto`}</div>
            )}
          </div>
          <div className="selectedTiketsList">
            {tiketsList.map((tik, i) => {
              return (
                <div className="tiketSelected" key={`tiketComplete${i}`}>
                  <div>
                    {tik.map((t, o) =>
                      o + 1 != raffle.numbersTiket
                        ? `${t.number
                            .toString()
                            .padStart(raffle.sizeTikets, "0")} ,`
                        : `${t.number
                            .toString()
                            .padStart(raffle.sizeTikets, "0")} `
                    )}
                  </div>
                  <img
                    src={close}
                    className="imgClose"
                    onClick={() => handlerRemve(tik)}
                  />
                </div>
              );
            })}
          </div>
          <button className="btnGeneratorDesktop" onClick={autoGenerate}>
            Generador automatico
          </button>
          <div className="amountMsj">Total a pagar</div>
          <div className="amount">${tiketsList.length * raffle.price}</div>
        </div>
        <div className="btnBuy" onClick={handlerPopUp}>
          Comprar boletos
        </div>
      </div>
      <div className="selectionTikets">
        <div className="titleSelection">Seleccionados</div>
        <div className="bodyTiketsSelected">
          {tiketsList.map((tik, i) => {
            return (
              <div className="tiketSelected" key={`tiketComplete${i}`}>
                <div>
                  {tik.map((t, o) =>
                    o + 1 != raffle.numbersTiket
                      ? `${t.number
                          .toString()
                          .padStart(raffle.sizeTikets, "0")} ,`
                      : `${t.number
                          .toString()
                          .padStart(raffle.sizeTikets, "0")} `
                  )}
                </div>
                <img
                  src={close}
                  className="imgClose"
                  onClick={() => handlerRemve(tik)}
                />
              </div>
            );
          })}
        </div>
      </div>
      <button className="btnGenerator" onClick={autoGenerate}>
        Generador automatico
      </button>

      {tikets.length > 0 && (
        <div className="movilOportunities">
          {`Faltan ${
            raffle.numbersTiket - tikets.length
          } numeros para un boleto`}
        </div>
      )}
      <div className="movilButtons">
        <div className="btnm btnm-amount">
          ${tiketsList.length * raffle.price}
        </div>
        <button className="btnm btnm-buy" onClick={handlerPopUp}>
          Comprar
        </button>
      </div>
    </div>
  );
};

export default TiketsScreen;
