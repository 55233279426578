import "./HomeScreen.css";

import React, { useEffect, useState } from "react";
import rafflesHelpers from "../../../helpers/raffles";
import { useNavigate } from "react-router-dom";
import idea from "../../../assets/idea.png";
import multi from "../../../assets/multi.png";
import construction from "../../../assets/construction.png";

const HomeScreen = () => {
  const navigation = useNavigate();

  const [raffles, setraffles] = useState([]);
  useEffect(() => {
    getRaffles();
  }, []);
  const getRaffles = async () => {
    const response = await rafflesHelpers.raffles(
      {
        title: 1,
        profileImg: 1,
        price: 1,
        numbersTiket: 1,
        finish: 1,
        userName: 1,
        preRaffle: 1,
      },
      0,
      10
    );
    console.log(response);
    setraffles(response.data.myRaffles);
  };

  return (
    <div className="pg">
      <div id="popup1" class="overlay">
        <div class="popup">
          <h2>¡Hola!</h2>
          <a class="close" href="#">
            &times;
          </a>
          <div className="imgContentPop">
            <img src={construction} className="iconPop" />
          </div>

          <div class="content">
            Estamos trabajando en automatizar este proceso, si te interesa crear
            una nueva rifa contactanos al numero
            <span className="resalt"> 8186606969</span> o envia un correo a
            <span className="resalt"> alanrifasysorteos@gmail.com </span>
          </div>
        </div>
      </div>
      <div className="listRafflesContainer">
        <a class="button" href="#popup1">
          Crear tu rifa
        </a>
        {raffles.map((raffle) => (
          <div
            className="raffleItem"
            onClick={() =>
              navigation(`../rifa/${raffle.userName}/${raffle._id}`)
            }
          >
            <div
              className="profileImgItem"
              style={{ "background-image": `url('${raffle.profileImg}')` }}
            ></div>
            <div className="bodyItem">
              <div className="headSectionItem">
                <div className="titleItem">{raffle.title}</div>
              </div>
              <div className="dataItem">
                <div className="sectionItem">
                  Precio del boleto ${raffle.price} MXN
                </div>
                <div className="sectionItem">
                  Oportunidades {raffle.numbersTiket} por boleto
                </div>
                <div className="sectionItem">
                  Finaliza {new Date(raffle.finish).toLocaleDateString("en-GB")}
                </div>
              </div>
              <div className="looks">
                {raffle.numbersTiket > 1 && (
                  <div className="multiRaffle">
                    Multi boletos
                    <img src={multi} className="iconLed" />
                  </div>
                )}
                {raffle.preRaffle && (
                  <div className="preRaffle">
                    Pre sorteos
                    <img src={idea} className="iconLed" />
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeScreen;
