import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import rafflesHelpers from "../../../helpers/raffles";
import "../DetallesRifa/RaffleDetails.css";
import Lightbox, { ImagesListType } from "react-spring-lightbox";

import close from "../../../assets/closeW.png";
import win from "../../../assets/military_tech.png";
import winB from "../../../assets/military_tech_blue.png";

const RaffleDetailsScreen = ({ setId, setUser }) => {
  const navigation = useNavigate();
  let { id, user } = useParams();
  const [lightbox, setlightbox] = useState(false);
  const [raffle, setRaffle] = useState({ state: false });
  const [images, setImages] = useState([]);
  /* console.log(user);
  console.log(id); */
  setId(id);
  setUser(user);
  const [currentImageIndex, setCurrentIndex] = useState(0);

  const gotoPrevious = () =>
    currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

  const gotoNext = () =>
    currentImageIndex + 1 < images.length &&
    setCurrentIndex(currentImageIndex + 1);

  useEffect(() => {
    getRaffle();
  }, []);
  const getRaffle = async () => {
    try {
      const raffle = await rafflesHelpers.raffleId(id, {
        title: 1,
        subTitle: 1,
        imgs: 1,
        sections: 1,
        tikets: 1,
        profileImg: 1,
        numbersTiket: 1,
      });
      console.log(raffle.data.myRaffles);
      const available = raffle.data.myRaffles.tikets.filter(
        (raff) => raff.state == 1 || (raff.state == 2 && isValidDate(raff.at))
      ).length;
      const availableNo = raffle.data.myRaffles.tikets.filter(
        (raff) => raff.state == 2
      ).length;
      console.log("available " + available);
      console.log("no available " + availableNo);
      setRaffle({ ...raffle.data.myRaffles, state: true, available });
      setImages(
        raffle.data.myRaffles.imgs.map((img) => ({
          src: img,
          loading: "lazy",
          alt: "example",
        }))
      );
    } catch (e) {
      console.log(e);
    }
  };
  const isValidDate = (at) => {
    let tiketTime = new Date(at);

    let awaitingTime = new Date();
    awaitingTime.setHours(awaitingTime.getHours() - raffle.awaitingTime);

    if (tiketTime.getTime() > awaitingTime.getTime()) {
      return false;
    } else {
      return true;
    }
  };
  const goToLink = (link) => {
    //console.log(link);
    window.location.assign(link);
  };
  return raffle.state ? (
    <div>
      <div
        className="mainImage"
        id="mainImage"
        style={{
          "background-image": `url('${raffle.profileImg}')`,
        }}
      >
        <div className="headImageContainer">
          <button className="buttonImages" onClick={() => setlightbox(true)}>
            Ver imagenes ({raffle.imgs.length})
          </button>
          <button className="enableTikets">{`Boletos disponibles ${
            raffle.available / raffle.numbersTiket
          }/${raffle.tikets.length / raffle.numbersTiket}`}</button>
        </div>
        <div className="headImageContainer">
          <div className="sectionTitles">
            <h3 className="titleRaffle">{raffle.title}</h3>
            <div className="subTitleRaffle">{raffle.subTitle}</div>
          </div>
          <button
            className="buyButton"
            onClick={() => navigation(`../buyTikets/${user}/${id}`)}
          >
            Comprar boletos
          </button>
        </div>
      </div>
      {raffle.sections.map(({ title, subSection, _id }, i) => (
        <div key={_id}>
          <div className="sectionContent" id={`AS${i}`}>
            <h5 className="sectionTitle">{title}</h5>
          </div>
          <div className="bodySection">
            {subSection.map(
              ({
                type,
                simpleText,
                simpleIcons,
                _id,
                icon,
                title,
                step,
                finally: fin,
                preRaffle,
              }) => {
                if (type === "p") {
                  return (
                    <p key={_id} className="simpleP">
                      {simpleText}
                    </p>
                  );
                } else if (type === "s") {
                  return (
                    <p key={_id} className="subTitleSection">
                      {simpleText}
                    </p>
                  );
                } else if (type === "i") {
                  return (
                    <div key={_id} className="imgContent">
                      {simpleIcons.map(({ url, redirect }, i) => (
                        <img
                          src={url}
                          className="littleImg"
                          key={`img${i}`}
                          onClick={() => goToLink(redirect)}
                        />
                      ))}
                      {/*  
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/20/20673.png"
                      className="littleImg"
                    /> */}
                    </div>
                  );
                } else if (type === "op") {
                  return (
                    <div className="containerOp">
                      <div className="stepOp">{step}</div>
                      <div className="bodyOp">
                        <img src={icon} className="iconOp" />
                        <div>
                          <div className="titleOp">{title}</div>
                          <div className="textOp">{simpleText}</div>
                        </div>
                      </div>
                    </div>
                  );
                } else if (type === "pr") {
                  return fin.map((pr) => (
                    <div className="prizeContainer">
                      <div className="fstPrize">
                        <div className="placePr">{pr.place}</div>
                        <div>
                          <div className="titlePr">{pr.title}</div>
                          <div className="descPr">{pr.description}</div>
                        </div>
                        <img src={win} className="iconPr" />
                      </div>
                    </div>
                  ));
                } else if (type === "prp") {
                  return (
                    <div className="prizeContainer">
                      {preRaffle.map((pr) => (
                        <div className="prpPrize">
                          <div className="prpContainer">
                            <div className="titlePr">{pr.title}</div>
                            <div className="momentPr">{pr.moment}</div>
                            <div className="descPr">{pr.description}</div>
                          </div>
                          <img src={winB} className="iconPr" />
                        </div>
                      ))}
                    </div>
                  );
                }
              }
            )}

            {/* <p className="simpleP">Se emiten 2500 boletos</p>
            <p className="simpleP">
              Premios: 1ero Chevrolet Trax 2019 + tanque lleno + 10/12s, 2do 50
              Mil pesos + 5/12s, 3ero 25 Mil pesos + 5/12s
            </p>
            <p className="simpleP">
              Con tu boleto pagado estas participando para ganar los premios
              mencionados, tienes 4 aportunidades para ganar por boleto
            </p> */}
          </div>
        </div>
      ))}

      <Lightbox
        isOpen={lightbox}
        onPrev={gotoPrevious}
        onNext={gotoNext}
        images={images}
        currentIndex={currentImageIndex}
        /* Add your own UI */
        renderHeader={() => (
          <div className="headerLightbox">
            <img
              src={close}
              className="closebtn"
              onClick={() => setlightbox(false)}
            />
          </div>
        )}
        // renderFooter={() => (<CustomFooter />)}
        //renderPrevButton={() => (<CustomLeftArrowButton />)}
        // renderNextButton={() => (<CustomRightArrowButton />)}
        // renderImageOverlay={() => (<ImageOverlayComponent >)}

        /* Add styling */
        // className="cool-class"
        style={{ background: "black" }}
        /* Handle closing */
        onClose={() => setlightbox(false)}

        /* Use single or double click to zoom */
        // singleClickToZoom

        /* react-spring config for open/close animation */
        // pageTransitionConfig={{
        //   from: { transform: "scale(0.75)", opacity: 0 },
        //   enter: { transform: "scale(1)", opacity: 1 },
        //   leave: { transform: "scale(0.75)", opacity: 0 },
        //   config: { mass: 1, tension: 320, friction: 32 }
        // }}
      />
      <div className="sectionContentFeet">
        <Link className="sectionTitle" to="/">
          www.rifasysorteos.com
        </Link>
      </div>
      <div className="sectionContent">
        <h5 className="sectionTitle"></h5>
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default RaffleDetailsScreen;
