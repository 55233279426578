import axios from "axios";
//import {interceptorHandler} from '../interceptors';
import { createEndpoint } from "./create_enpoints";

// Log app env
const axiosConfig = axios.create({
  baseURL: `https://api.rifasysorteos.com` /* "http://192.168.3.79:3001" */,
  responseType: "json",
  headers: {
    Accept: "application/json",
  },
  validateStatus: async () => true,
});
axiosConfig.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("token");
    if (accessToken) {
      config.headers = { token: `${accessToken}` };
    }

    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error.response || error.message);
  }
);
console.log("Pointed API: ", axiosConfig);
/* axios.interceptors.request.use(
  async (config) => {
    config.headers.common = {
      token: (await localStorage.getItem("token")) || "",
    };
    return config;
  },
  null,
  { synchronous: true }
); */

const routes = {
  user: {
    login: "/login",
  },
  private: {
    myRaffles: "/myRaffles",
    myTikets: "/myTikets",
    paidTikets: "/paidTikets",
  },
  public: {
    myRaffle: "/myRafflesId",
    raffleId: "/raffleId",
    raffles: "/raffles",
    updateTikets: "updateTikets",
  },
};
export const rifaEndpoints = {
  login: createEndpoint(routes.user.login, axiosConfig),
  myRaffles: createEndpoint(routes.private.myRaffles, axiosConfig),
  myRaffle: createEndpoint(routes.public.myRaffle, axiosConfig),
  raffleId: createEndpoint(routes.public.raffleId, axiosConfig),
  myTiekts: createEndpoint(routes.private.myTikets, axiosConfig),
  raffles: createEndpoint(routes.public.raffles, axiosConfig),
  updateTikets: createEndpoint(routes.public.updateTikets, axiosConfig),
  paidTikets: createEndpoint(routes.private.paidTikets, axiosConfig),
};
