import { rifaEndpoints } from "../api/api_instance";

const rafflesHelpers = {
  myRaffles: () => {
    return rifaEndpoints.myRaffles.get();
  },
  myRaffleId: (id, params) => {
    return rifaEndpoints.myRaffle.post({ id, params });
  },
  raffleId: (id, params) => {
    return rifaEndpoints.raffleId.post({ id, params });
  },
  myTikets: (id, params) => {
    return rifaEndpoints.myTiekts.post({ id, params });
  },
  raffles: (params, skip, limit) => {
    return rifaEndpoints.raffles.post({ params, skip, limit });
  },
  updateTikets: (tikets, _id, customer) =>
    rifaEndpoints.updateTikets.post({ tikets, _id, customer }),
  paidTikets: (numbers, _id, tiket_id) =>
    rifaEndpoints.paidTikets.post({ numbers, _id, tiket_id }),
};

export default rafflesHelpers;
