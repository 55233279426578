import React, { useContext, useEffect, useState } from "react";
import rafflesHelpers from "../../../helpers/raffles";
import "./AdminHome.css";
import close from "../../../assets/closeW.png";
import { Data } from "../../../App";

const AdminHomeScreen = () => {
  const [raffles, setraffles] = useState([]);
  const [tikets, setTikets] = useState([]);
  const [option, setoption] = useState("raffle");
  const [raffleSearch, setRaffleSearch] = useState(null);
  const data = useContext(Data);

  useEffect(() => {
    if (option !== null) getTikets();
    return () => {};
  }, [raffleSearch]);
  useEffect(() => {
    if (option === "raffle" && raffles.length === 0) getRaffles();
    return () => {};
  }, [option]);
  const getTikets = async () => {
    try {
      const response = await rafflesHelpers.myTikets(raffleSearch._id, {
        numbers: 1,
        _id: 1,
        customer_id: 1,
        payed: 1,
        at: 1,
        raffle_id: 1,
        sizeTikets: 1,
      });
      console.log(response.data.myRaffles);
      setTikets(
        response.data.myRaffles.sort((a, b) => new Date(b.at) - new Date(a.at))
      );
    } catch (e) {}
  };
  const getRaffles = async () => {
    try {
      const response = await rafflesHelpers.myRaffles();
      console.log(response);
      setraffles(response.data.myRaffles);
    } catch (e) {}
  };
  const handlerPayed = async (numbers, raffle_id, tik_id) => {
    data.setloading(true);
    const response = await rafflesHelpers.paidTikets(
      numbers,
      raffle_id,
      tik_id
    );

    if (response.data.ok) {
      data.snackbar.success(response.data.msg);
      setTikets(
        tikets.map((tik) =>
          tik._id === tik_id ? { ...tik, payed: true } : tik
        )
      );
    } else {
      data.snackbar.error(response.data.msg);
      console.log(response.data.msg);
    }
    data.setloading(false);
  };
  return (
    <div className="contentBodyRaffles">
      <div className="contentBody">
        <div className="optionsRaffles">
          <button
            className={option === "raffle" ? "optionSelected" : "optionRaffle"}
            onClick={() => setoption("raffle")}
          >
            Rifas
          </button>
          <button
            onClick={() => setoption("tikets")}
            className={option === "tikets" ? "optionSelected" : "optionRaffle"}
          >
            Boletos
          </button>
          <button
            onClick={() => setoption("numbers")}
            className={option === "numbers" ? "optionSelected" : "optionRaffle"}
          >
            Numeros
          </button>
        </div>
        <div className="contantRafflesList">
          {raffleSearch === null &&
            raffles.map(({ _id, title, sizeTikets }) => (
              <div
                className="raffleContainer"
                key={_id}
                onClick={() => setRaffleSearch({ _id, title, sizeTikets })}
              >
                {title}
              </div>
            ))}
          {raffleSearch !== null && (
            <div
              className="raffleContainerSelected"
              /* onClick={() => setRaffleSearch({ _id, title })} */
            >
              {raffleSearch.title}
              <img
                src={close}
                className="closebtn"
                onClick={() => setRaffleSearch(null)}
              />
            </div>
          )}
          {raffleSearch !== null &&
            tikets.map((tik) => (
              <div className="tiket">
                <div className="ptik">{`ID: ${tik._id}`}</div>
                <div className="ptik">{`Fecha: ${new Intl.DateTimeFormat(
                  "en-US",
                  {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  }
                ).format(new Date(tik.at))}`}</div>
                <div className="ptik">{`Nombre: ${tik.customer_id.name}`}</div>
                <div className="ptik">{`Estado: ${tik.customer_id.state}`}</div>
                <div className="ptik">{`Ciudad: ${tik.customer_id.city}`}</div>
                <div className="ptik">{`Telefono: ${tik.customer_id.phone}`}</div>
                {tik.numbers.length > 0 && (
                  <div className="ptik">
                    {`Numeros:`}
                    {tik.numbers.map((n) => (
                      <span className="numTik">
                        {n.toString().padStart(raffleSearch.sizeTikets, "0")}
                      </span>
                    ))}
                  </div>
                )}

                <div className="ptik space">
                  {`Estatus:`}{" "}
                  <span className={tik.payed ? "tiketPaid" : "tiketNotPaid"}>
                    {tik.payed ? "Pagado" : "Pendinete"}
                  </span>
                </div>
                {!tik.payed && (
                  <div className="optTik">
                    <button className="opti optir">Eliminar</button>
                    <button className="opti optie">Editar</button>
                    <button
                      className="opti optia"
                      onClick={() =>
                        handlerPayed(tik.numbers, tik.raffle_id, tik._id)
                      }
                    >
                      Aceptar
                    </button>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AdminHomeScreen;
