import "./navbar.css";
import { Link, NavLink } from "react-router-dom";
import { Data } from "../../App";
import { useContext, useEffect } from "react";

export const Navbar = () => {
  const data = useContext(Data);
  useEffect(() => {
    console.log(data.data.user.login);
  }, [data.data.user]);

  return (
    <nav className="topBar">
      <div>
        <img
          src="https://rifas-files.s3.amazonaws.com/rifas-files/logo/rifa_logo.png"
          className="logo"
        />
      </div>
      <ul>
        <li>
          <NavLink to="/home">Home</NavLink>
        </li>

        {data.data.user.login && (
          <li>
            <Link to="/adminHome">Rifas</Link>
          </li>
        )}

        {!data.data.user.login ? (
          <li>
            <Link to="/login">Login</Link>
          </li>
        ) : (
          <li>
            <button onClick={() => data.cleanSesion()} className="closeSesion">
              Cerrar sesion
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
};
